.header-container {
  width: 100%;
  height: 35vh;
  position: relative;

  background-image: url(./assets/img/header-bg.jpg);
  background-size: cover;
  margin-top: 0%;
  margin-left: 0;
  background-position: center;
}

.admin_action_flex{
  display: flex !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  justify-content: space-between !important;
    
}
.admin_table_item_align{
  text-align: center ;
}
.admin_table_noitem_align{
  padding: 0 !important;
}
.ant-table-wrapper{

  overflow-x: auto !important;
  overflow: auto;
  width: 100%;
}
.serach_MR{
  margin-right: 20px !important;
}
.spin_table{
  width: 50px;
  height: 50px;
 
  /* Center vertically and horizontally */
  position: fixed;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px
}
.full_height .ant-modal-content{
  height: 100% !important;
}
.link_navigate{
  color: blue;
  cursor: pointer;
}
.ant-modal-header{
background-color: #1CDBBC !important;
}
.btn_primary_pwddoc{
  float:inline-start;
  /* margin-right:20%; */
  border-radius:5px !important;
  position: absolute;
    right: -35px;
    z-index: 1000;
    top: -4px;
    margin-top: 2px;
}
.ant-collapse-header{
 background:  #1CDBBC !important;
 color: #2b2727 !important
 
 
}
.base-layout{
  padding-bottom: 60px;
}
.col_gap .ant-col-4,.ant-col-8{
  padding: 5px !important;
}
/* .ant-modal-body{
  overflow: auto;
  max-height: 503px;
} */
.ant-collapse-content{
  padding-left: 5%;
  padding-right: 5%;
}
.col_gap .ant-col-6{
  padding: 20px !important;
}
.pwd_panel .ant-collapse-header{
  margin-bottom: -44px;
  top:-44px;
 width: 100%;
 margin-top: 44px;

}
.admin_filter_button{
  display: flex;
  margin-top: -57px !important;
  float: right !important;
  margin-bottom: 20px !important;
  width: 27% !important;
}
.ant-table-wrapper{
  overflow-x: auto !important;
}
.width_status{
  width: 226px !important;
}
.admin_searchicon_button{
  height: 32px;
    width: 37px;
    margin-top: 4px;
    background: #1CDBBC;
    color: white;
    border: 1px solid #1CDBBC;
    border-radius: 3px;
}
.vialto-logo {
  margin-top: 0px;
  padding-top: 72px;
  padding-left: 0px;
  padding-right: 0px;
  height: 150px;
  position: relative;
  box-sizing: border-box;
  background: no-repeat;
  background-image: url(./assets/img/vialto-logo-outline-white.svg);
}
.pwdform .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input, .pwdform .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover{
  background: #fff;
    border-color: #b7afaf !important;
}
.pwdform .ant-form-item-has-error .ant-input:focus{
  box-shadow: none !important;
}
.mytrips-logo {
  margin-top: 0px;
  margin-bottom: 30px;
  padding-top: 100px;
  padding-left: 0px;
  padding-right: 0px;

  position: relative;
  box-sizing: border-box;
  background: no-repeat;
  background-image: url(./assets/img/mytrips-logo-ondark.svg);
  float: right;
  width: 45%;
}
.mytrips-shadow {
  height: 100%;
  float: right;
  opacity: 0.25;
  top: 3px;
  left: 1px;
  margin-top: 100px;
  padding-top: 72px;
  padding-left: 15px;
  padding-right: 15px;
  vertical-align: middle;
  position: absolute;
  filter: blur(1.8px);
  box-sizing: border-box;
  background: no-repeat;
  background-image: url(./assets/img/mytrips-logo-shadow.svg);
}
.pagination{
  justify-content: flex-end !important;
  padding-bottom: 70px;
  padding-top: 20px;
}
.required:after {
  content:" *";
  color: red;
}
.inputFilter_upload_MB{
  margin-bottom: 10px;
}
.upload_file_MT{
  margin-top: 10px !important;
}
.error_msg_upload_file{
  color: red;
  margin-bottom: -21px !important;
}
.container_MT{
  margin-top: 70px !important;
}
.flex{
  display: flex !important;
}
.pagination_wrapper{
  display: flex;
  justify-content: end;
}
.pagination_select{
  width: 11%;
  margin-top: 20px;
}
.page-link{
color: black !important ;
}
.checkbox_cb_action{
  accent-color: #8bf5e3 !important;
}
.page-item.active .page-link{
  color: black !important;
    background-color: #8bf5e3 !important;
    border-color: #8bf5e3 !important;
}
.admin_table_wrapper{
  overflow-x: auto;
    width: 100%;
    border: 1px solid #c1c1c1;
}
.treeselect{
  width: 45%;
  margin-top: 21px;
}
.react_paginator{
  margin-left: -44px;
}
.button_container{
margin-top: 22px;
}
.width{
  width: 100% !important;
}
.margin_right{
  margin-right: 20px !important;
}
.select{
  width: 10% ;
  margin-right: 20px;
}
.export_csv_btn{
  margin-right: 10px;
    margin-left: 15px;
    z-index: 1000;
}
.width_input{
  width: 155px !important;
}

.footer-container {
  width: 100%;
  height: 30px;
  margin-top: 60px;
  border-top: #4000ef 5px solid;
  text-align: center;
  padding-top: 10px;
}
.header-menu-option:hover {
  cursor: pointer;
}

.header-menu-option {
  margin-top: 20px;
  font-weight: 600;
}
.form {
  width: 100%;
  height: auto;
  display: inline-table;
  justify-items: center;
  justify-content: center;
  margin-top: 0px;

  text-align: center;
  background: #f1f1f1;
  padding-top: 0px;
}
.chatbotIcon {
  border: solid orange;
  text-align: center;

  border-radius: 50%;
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  background-color: orange;
  image-orientation: flip;
}
.moveDown {
  position: relative;
  top: 3px;
}
.editor-container {
  vertical-align: middle;
  position: absolute;
  top: 20vh;
  left: 20vh;
  right: 20vh;
  display: inline-table;
  justify-items: center;
  height: 40%;
  min-height: 16em;
  width: 80%;
  justify-content: center;
}
.ql-container {
  height: auto !important;
  min-height: 200px;
}

.ql-editor {
  height: auto !important;
  min-height: 200px;
}

.ant-btn {
  padding: 18px 30px;
  font-size: 16px;
  border-radius: 24px !important;
  color: #000000;
  background: #fff;
  border-color: #000000;
  line-height: 0;
}

.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 24px !important;
}

.admin_cards_wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 350px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-landing {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.card-body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.card-text {
  margin: 0;
}

.btn {
  align-self: flex-start;
  background-color: #0A838A;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  text-decoration: none;
}

.btn:hover {
  background-color: #086a6e;
}