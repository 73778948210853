@font-face {
  font-family: 'Aeonik-Medium';
  src: url('../../assets/fonts/vialto/Aeonik-Medium.woff2') format('woff2'),
  url('../../assets/fonts/vialto/Aeonik-Medium.woff') format('woff');
}

@font-face {
font-family: 'Aeonik-Regular';
    src: url('../../assets/fonts/vialto/Aeonik-Regular.woff2') format('woff2'),
        url('../../assets/fonts/vialto/Aeonik-RegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Aeonik-Bold';
  src: url('../../assets/fonts/vialto/Aeonik-Bold.woff2') format('woff2'),
      url('../../assets/fonts/vialto/Aeonik-Bold.woff') format('woff');
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Web Aeonik", sans-serif, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.mw {
  max-width: 100%;
}

.plr-20 {
  padding: 20px 20px;
}

.layer {
  background-color: #fff;
  padding-top: 170px;
  width: 100%;
  height: 100%;
}

.screen-container {
  font-family: 'Aeonik-Regular';
  padding-bottom: 30px;
}

.sticky-nav {
  height: 55px;
  background-color: white;
}

.nav-actions {
  height: 55px;
  background-color: rgb(0, 0, 0);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 25px;
  border-bottom: 1px solid #000000;
}

.left-nav {
  display: flex;
  justify-content: flex-start;
  font-family: boldFont;
  text-align: center;
}

.logo-nav {
  width: 90px;
  margin-right: 30px;
}

.my-trips-plus-nav {
  font-family: boldFont;
  text-align: center;
  color: rgb(255, 255, 255);
  font-size: 18px;
}

.plus {
  color: #1CDBBC;
}

.header-text {
  display: flex;
  justify-content: flex-start;
  padding-left: 10px;
  color: white;
  font-family: 'Aeonik-Bold' !important;
}

.header-text_title {
  font-size: 24px;
}

.header-text_description {
  font-size: 18px;
}

.header-description {
  display: flex;
  justify-content: flex-start;
  font-size: 18px;
  padding-left: 10px;
  color: white;
  font-family: boldFont;
}

.header-info {
  display: 'flex';
  justify-content: 'center';
  padding-top: 30px;
  padding-bottom: 30px;
  font-family: boldFont;
  font-size: 16px;
  color: black;
}
.header-info-form {
  display: 'flex';
  justify-content: 'center';
  padding-bottom: 30px;
  font-family: boldFont;
  font-size: 16px;
  color: black;
}
.form-container {
  background-color: #F7F5F5;
  border: 0px solid #dedede;
  margin: 0 auto;
  margin-bottom: 25px;
  width: 50%;
}

.submitted-form {
  text-align: center;
  font-family: boldFont;
  font-size: 24px;
  color: black;
  margin: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.s-container {
  width: 100%;
  height: 80px;
  margin-bottom: 200px;
  border-bottom: hsl(246, 100%, 47%) 5px solid;
}

.category {
  margin-bottom: 25px;
  margin-top: 10px;
  color: black;
  font-size: 16px;
  font-family: 'Aeonik-Bold';
  font-weight: 0;
}

.buttons-container {
  display: flex;
  justify-content: center;
}

.action-button {
  font-size: 12px;
  width: 150px;
  margin: 10px;
  border-radius: 5px;
}

.footer {
  display: flex;
  justify-content: 'flex-start';
  height: 60px;
  color: #f7f7f7;
  padding: 5px;
  z-index: 1000;
  font-size: 12px;
  text-align: 'left';
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #2d2d2d;
}

.footer-copyrights {
  margin-left: 15px;
  margin-top: 5px;
  color: lightgray;
}

.footer-container {
  width: 100%;
  height: 30px;
  margin-top: 60px;
  border-top: #4000ef 5px solid;
  text-align: center;
  padding-top: 10px;
}
.header-menu-option:hover {
  cursor: pointer;
}

.header-menu-option {
  margin-top: 20px;
  font-weight: 600;
}
.sform {
  padding-left: 20px;
}

.vialto {
  box-sizing: border-box;
  background: no-repeat;
  height: 40px;
  width: 40px;
  margin-top: 10px;
  margin-left: 5px;
  background-image: url(../../assets/img/vialto-logo-outline-white.svg);
}

.links {
  display: flex;
  margin-top: 5px;
}

.link {
  margin-right: 15px;
  font-weight: bold;
  cursor: pointer;
}

.link:hover {
  color: white;
}

.trips {
  box-sizing: border-box;
  background: no-repeat;
  height: 25px;
  width: 50px;
  background-image: url(../../assets/img/icon-vialto.svg);
}

.ant-form-item.ant-form-item-view-mode {
  margin-bottom: 20px;
}
.ant-form-item.ant-form-item-view-mode-v4 {
  margin-bottom: 5px;
}

.ant-form-item-view-mode .ant-form-item-label {
  text-align: left;
  font-weight: 400;
  font-size: '10px';
  line-height: 1.5;
}

.ant-form-item-view-mode .ant-form-item-control {
  line-height: 1.5;
}

.ant-form-item-children .antd-form-builder-string-content {
  line-height: 1.5;
  display: inline-block;
  padding-top: 9px;
}

.ant-form-item-view-mode .antd-form-builder-string-content {
  display: inline;
  padding-top: 0;
}

.antd-form-builder-question-icon {
  fill: #40a9ff;
  width: 16px;
  height: 16px;
  vertical-align: middle;
}

.ant-input {
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: #ffffff !important;
  color: black;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.ant-input:hover {
  border: 1px solid #1CDBBC;
}

.ant-input:selected {
  border: 1px solid #1CDBBC;
}

.ant-form-item-label > label {
  position: relative;
  display: inline-flex;
  align-items: center;
  height: 32px;
  font-family: 'Aeonik-Regular';
  font-size: 12px !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  content: none !important;
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 16px;
  color: #1CDBBC;
  font-weight: bold;
  font-size: 10px;
  font-family: "Web Aeonik", sans-serif, SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-picker {
  border: 1px solid #ccc;
  border-radius: 0px;
  background-color: #ffffff !important;
  color: black;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  width: 100%;
}

.anticon.help-icon {
  color: rgb(64, 169, 255);
  font-size: 15px;
  margin-left: 5px;
  margin-right: 2px;
  cursor: pointer;
}


.landing-text {
  display: flex;
  justify-content: center;
  line-height: 40px;
  padding-top: 10px;
  text-align: center;
}


.ant-btn {
  padding: 18px 30px;
  font-size: 16px;
  border-radius: 24px !important;
  color: #000000;
  background: #fff;
  border-color: #000000;
  line-height: 0;
}

.ant-btn-lg {
  height: 40px;
  padding: 6.4px 15px;
  font-size: 16px;
  border-radius: 24px !important;
}

.ant-progress-inner {
  background-color: #D5D5D5 !important;
}

.settings-btn:hover {
  color: #1CDBBC !important;
}

.settings-btn:active {
  color: #1CDBBC !important;
}